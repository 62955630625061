<template>
  <!-- <dv-border-box-6> -->
    <div class="project-data">
      <module-title type="n" icon="project">全国落地项目数据</module-title>
      <!-- <h2 class="title">全国落地项目数据</h2> -->
      <div class="data-wrap">
        <div class="item">
          <div class="lab">已落地项目</div>
          <dv-digital-flop class="num" :config="conf.conf1"/>
        </div>
        <div class="item">
          <div class="lab">准落地项目</div>
          <dv-digital-flop class="num" :config="conf.conf2" />
        </div>
        <div class="item">
          <div class="lab">推进中项目</div>
          <dv-digital-flop class="num" :config="conf.conf3" />
        </div>
      </div>
    </div>
  <!-- </dv-border-box-6> -->
</template>

<script>
import ModuleTitle from "../components/ModuleTitle";
function formatter (number) {
  const numbers = number.toString().split('').reverse()
  const segs = []

  while (numbers.length) segs.push(numbers.splice(0, 3).join(''))

  return segs.join(',').split('').reverse().join('')
}
export default {
  components: {
    ModuleTitle
  },
  props: {
    originData: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    conf: function() {
      return {
        conf1: {
          number: [this.originData.landedProjectNumber],
          content: '{nt}',
          textAlign: 'left',
          style: {
            fontSize: '40',
            fill: '#00ddff',
          },
          formatter
        },
        conf2: {
          number: [this.originData.almostLandedProjectNumber],
          content: '{nt}',
          textAlign: 'left',
          style: {
            fontSize: '40',
            fill: '#00ddff',
          },
          formatter
        },
        conf3: {
          number: [this.originData.progressProjectNumber],
          content: '{nt}',
          textAlign: 'left',
          style: {
            fontSize: '40',
            fill: '#00ddff',
          },
          formatter
        },
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.project-data {
  padding: 28px;
  .title {
    font-weight: normal;
    margin-bottom: 30px;
  }
}
.data-wrap {
  .item {
    width: 100%;
    margin: 0px 0 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .lab {
      font-size: 18px;
      
    }
    .num {
      width: 160px;
      height: 54px;
    }
  }
}
</style>