<template>
  <div class="industry-linkage">
    <div class="one">
      <module-title type="m">地区资源链接数据</module-title>
      <v-chart class="chart" :option="option" autoresize />
    </div>
    <div class="two">
      <module-title type="m">各地投资回报率</module-title>
      <v-chart class="chart" :option="option2" autoresize />
    </div>
  </div>
</template>

<script>
import { use } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { BarChart } from 'echarts/charts';
import { PolarComponent  } from 'echarts/components'; 
import VChart, { THEME_KEY } from 'vue-echarts';
import { defineComponent, computed } from 'vue';
import ModuleTitle from '../components/ModuleTitle'

use([
  CanvasRenderer,
  BarChart,
  PolarComponent
]);


export default defineComponent({
  name: 'OrderMap',
  components: {
    VChart, ModuleTitle
  },
  provide: {
    [THEME_KEY]: 'dark',
  },
  props: {
    originData: {
      type: Object,
      default: () => {}
    }
  },
  setup(props) {
    // ['#2F8CF3', '#4EF0FF', '#FCA901']
    const colors = [
      {
        type: 'linear',
        x: 0,
        y: 0,
        x2: 0,
        y2: 1,
        colorStops: [{
            offset: 0, color: '#55c0ff' // 0% 处的颜色
        }, {
            offset: 1, color: 'rgba(15, 29, 204, 0)' // 100% 处的颜色
        }],
        global: false // 缺省为 false
      },
      {
        type: 'linear',
        x: 0,
        y: 0,
        x2: 0,
        y2: 1,
        colorStops: [{
            offset: 0, color: '#1ffff6' // 0% 处的颜色
        }, {
            offset: 1, color: 'rgba(78, 226, 255, 0)' // 100% 处的颜色
        }],
        global: false // 缺省为 false
      },
      {
        type: 'linear',
        x: 0,
        y: 0,
        x2: 0,
        y2: 1,
        colorStops: [{
            offset: 0, color: '#ff7603' // 0% 处的颜色
        }, {
            offset: 1, color: 'rgba(252, 115, 1, 0)' // 100% 处的颜色
        }],
        global: false // 缺省为 false
      }
    ] 
    const option = computed(() =>({
      backgroundColor: 'rgba(3, 21, 84, 0.8)',
      color: colors,
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross'
        }
      },
      textStyle: {
        color: '#fff'
      },
      grid: {
        left: '20%',
        right: '25%'
      },
      // toolbox: {
      //   feature: {
      //     dataView: { show: true, readOnly: false },
      //     restore: { show: true },
      //     saveAsImage: { show: true }
      //   }
      // },
      // legend: {
      //   data: ['项目注册用户', '订单交易总额']
      // },
      xAxis: [
        {
          type: 'category',
          axisTick: {
            alignWithLabel: true
          },
          // prettier-ignore
          data: ['高阳' , '云和', '莆田']
        }
      ],
      yAxis: [
        {
          type: 'value',
          name: '供应链交易合作金额（元）',
          position: 'left',
          alignTicks: true,
          // nameRotate: 15,
          axisLine: {
            show: true,
            lineStyle: {
              color: colors[0]
            }
          },
          // axisLabel: {
          //   formatter: '{value} 人'
          // }
        },
        {
          type: 'value',
          name: '品牌建设合作项目数量',
          position: 'right',
          alignTicks: true,
          // nameRotate: 15,
          axisLine: {
            show: true,
            lineStyle: {
              color: colors[1]
            }
          },
          // axisLabel: {
          //   formatter: '{value} 元'
          // }
          // max: function (value) {
          //   return value.max - 20
          // },
          // min: 0
        },
        {
          type: 'value',
          name: '产能订单下单数',
          position: 'right',
          offset: 60,
          alignTicks: true,
          // nameRotate: 15,
          nameLocation: 'start',
          axisLine: {
            show: true,
            lineStyle: {
              color: colors[2]
            }
          },
          // axisLabel: {
          //   formatter: '{value} °C'
          // }
        }
      ],
      series: [
        {
          name: '供应链交易合作金额',
          type: 'bar',
          data: [
            props.originData.gaoYangSupplyChainTransactionAmount,
            props.originData.yunHeSupplyChainTransactionAmount,
            props.originData.puTianSupplyChainTransactionAmount
          ],
          barWidth: 15,
          barGap: '40%',
          itemStyle: {
            borderRadius: 10, // 统一设置四个角的圆角大小
          }
        },
        {
          name: '品牌建设合作项目数量',
          type: 'bar',
          yAxisIndex: 1,
          data: [
            props.originData.gaoYangCooperativeProjectNumber,
            props.originData.yunHeCooperativeProjectNumber,
            props.originData.puTianCooperativeProjectNumber
          ],
          barWidth: 15,
          itemStyle: {
            borderRadius: 10, // 统一设置四个角的圆角大小
          }
        },
        {
          name: '产能订单下单数',
          type: 'bar',
          yAxisIndex: 2,
          data: [
            props.originData.gaoYangCapacityOrderNumber,
            props.originData.yunHeCapacityOrderNumber,
            props.originData.puTianCapacityOrderNumber
          ],
          barWidth: 15,
          itemStyle: {
            borderRadius: 10, // 统一设置四个角的圆角大小
          }
        }
      ]
    }))

    const option2 = computed(() => ({
      backgroundColor: 'rgba(3, 21, 84, 0.8)',
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross'
        },
        valueFormatter: (value) => value + '%'
      },
      textStyle: {
        color: '#fff'
      },
      xAxis: {
        type: 'value',
        name: '（%）',
        nameLocation: 'middle'
      },
      yAxis: {
        type: 'category',
        data: ['云和', '高阳', '杭州']
      },
      series: [
        {
          data: [
            props.originData.yunHeReturnRate,
            props.originData.gaoYangReturnRate,
            props.originData.hangZhouReturnRate,
          ],
          type: 'bar',
          itemStyle: {
            color: {
              type: 'linear',
              x: 1,
              y: 0,
              x2: 0,
              y2: 0,
              colorStops: [{
                  offset: 0, color: '#00D9FF' // 0% 处的颜色
              }, {
                  offset: 1, color: '#188df0' // 100% 处的颜色
              }],
              global: false // 缺省为 false
            }
          },
          barWidth: 15,
        }
      ]
    }))
    return { option, option2 }
  },
})
</script>

<style lang="scss" scoped>
.industry-linkage {
  display: flex;
  height: 100%;
  flex-direction: column;
  .one {
    height: 60%;
    display: flex;
    flex-direction: column;
  }
  .two {
    padding-top: 20px;
    height: 40%;
    display: flex;
    flex-direction: column;
  }
}
</style>