<template>
  <div class="footer">
    <div class="item">© 2017-{{curYear}} 杭州亿尚智能科技有限公司 版权所有 </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      curYear: new Date().getFullYear()
    }
  }
}
</script>

<style lang="scss" scoped>
.footer {
  height: 32px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  color: #fff;
}
</style>