<template>
  <div id="data-view">
    <dv-loading v-if="loading">加载中...</dv-loading>
    <dv-custom-full-screen-container v-else class="container">
      <top-header />
      <div class="main-content">
        <div class="left-wrap">
          <div class="right-one">
            <TrendData :origin-data="trendData" />
          </div>
          <div class="right-two">
            <IndustrialClusterData :origin-data="industrialClusterData" />
          </div>
        </div>
        <div class="middle-wrap">
          <div class="middle-top-wrap">
            <div class="middle-top-one">
              <UserData :origin-data="userData" />
            </div>
            <div class="middle-top-two">
              <ProjectData :origin-data="projectData" />
            </div>
          </div>
          <div class="middle-bottom">
            <ChinaMap />
          </div>
        </div>
        <div class="right-wrap">
          
          <div class="right-one">
            <OrderData :origin-data="orderData" />
          </div>
          <div class="right-two">
            <IndustryLinkageData :origin-data="industryLinkageData" />
          </div>
        </div>
      </div>
      <bottom-footer />
    </dv-custom-full-screen-container>
  </div>
</template>

<script>
import TopHeader from './TopHeader'
import BottomFooter from './BottomFooter'

import UserData from './UserData'
import ProjectData from './ProjectData'
import ChinaMap from './ChinaMap'
import OrderData from './OrderData'
import IndustrialClusterData from './IndustrialClusterData'
import TrendData from './TrendData'
import IndustryLinkageData from './IndustryLinkageData'

import { ajaxDatav } from '../utils/request'
export default {
  name: 'DataView',
  components: {
    TopHeader,
    BottomFooter,
    UserData,
    ProjectData,
    ChinaMap,
    OrderData,
    IndustrialClusterData,
    TrendData,
    IndustryLinkageData 
  },
  data() {
    return {
      loading: false,
      // 总用户数据
      userData: {
        totalUserRegisterNumber: '', // 总注册用户数量
        monthlyActiveNumber: '', // 月活数量
        newRegistrationsThisMonthNumber: '', // 本月新注册数量
        companyNumber: '', // 公司数量
      },
      // 全国落地项目数据
      projectData: {
        landedProjectNumber: '', // 已落地项目
        almostLandedProjectNumber: '', // 准落地项目
        progressProjectNumber: '', // 推进中项目
      },
      // 订单数据 - 总交易额
      orderData: {
        spotSupplyChainTransactionAmount: '', // 现货供应链交易额
        futuresCapacityTradingAmount: '', // 期货产能交易额 
        designInnovationServiceTransactionAmount: '', // 设计创新服务交易额  
        trainingServiceTransactionAmount: '', // 培训服务交易额  
        supplyChainFinancialTransactionAmount: '', // 供应链金融交易额
      },
      // 产业集群类
      industrialClusterData: {
        gaoYangRegisterNumber: '', // 高阳数据-项目注册用户
        gaoYangTransactionAmount: '', // 高阳数据-订单交易总额
        linPingRegisterNumber: '', // 临平数据-项目注册用户
        linPingTransactionAmount: '', // 临平数据-订单交易总额
        puTianRegisterNumber: '', // 莆田数据-项目注册用户
        puTianTransactionAmount: '', // 莆田数据-订单交易总额
        yunHeRegisterNumber: '', // 云和数据-项目注册用户
        yunHeTransactionAmount: '', // 云和数据-订单交易总额
      },
      // 走势变化趋势图 
      trendData: {
        gaoYangSupplyChainTransactionPercent: [], // 高阳-供应链交易提升百分比
        gaoYangCapacityTradingPercent: [], // 高阳-产能交易提升百分比
        gaoYangTalentServicePercent: [], // 高阳-人才服务提升百分比
        linPingSupplyChainTransactionPercent: [], // 临平-供应链交易提升百分比
        linPingCapacityTradingPercent: [], // 临平-产能交易提升百分比
        linPingTalentServicePercent: [], // 临平-人才服务提升百分比
        puTianSupplyChainTransactionPercent: [], // 莆田-供应链交易提升百分比
        puTianCapacityTradingPercent: [], // 莆田-产能交易提升百分比
        puTianTalentServicePercent: [], // 莆田-人才服务提升百分比
        yunHeSupplyChainTransactionPercent: [], // 云和-供应链交易提升百分比
        yunHeCapacityTradingPercent: [], // 云和-产能交易提升百分比
        yunHeTalentServicePercent: [], // 云和-人才服务提升百分比
      },
      // 产业联动效果图
      industryLinkageData: {
        // 产业联动效果图-地区资源链接数据
        gaoYangSupplyChainTransactionAmount: '', // 杭州-高阳-供应链交易合作金额
        gaoYangCooperativeProjectNumber: '', // 杭州-高阳-品牌建设合作项目数量
        gaoYangCapacityOrderNumber: '', // 杭州-高阳-产能订单下单数
        yunHeSupplyChainTransactionAmount: '', // 杭州-云和-供应链交易合作金额
        yunHeCooperativeProjectNumber: '', // 杭州-云和-品牌建设合作项目数量
        yunHeCapacityOrderNumber: '', // 杭州-云和-产能订单下单数
        puTianSupplyChainTransactionAmount: '', // 杭州-莆田-供应链交易合作金额
        puTianCooperativeProjectNumber: '', // 杭州-莆田-品牌建设合作项目数量
        puTianCapacityOrderNumber: '', // 杭州-莆田-产能订单下单数

        // 产业联动效果图-各地投资回报率
        yunHeReturnRate: '', // 云和
        gaoYangReturnRate: '', // 高阳
        hangZhouReturnRate: '', // 杭州
      },
      

      timer: null,
    }
  },
  created() {
    // 测试
    // setTimeout(() => {
    //   console.log('msg')
    //   this.$msg('Hello')
    // }, 2000)

    // 获取数据
    const mock = false
    if (mock) {
      this.getMockData()
    } else {
      this.getData('init')
      this.timer = setInterval(() => {
        this.getData()
      }, 30000)
    }

    
  },
  destroyed() {
    clearInterval(this.timer)
  },
  methods: {
    getMockData() {
      this.userData = {
        totalUserRegisterNumber: 23434,
        monthlyActiveNumber: 4568,
        newRegistrationsThisMonthNumber: 256,
        companyNumber: 8992,
      }

      this.projectData = {
        landedProjectNumber: 54,
        almostLandedProjectNumber: 34,
        progressProjectNumber: 23,
      }

      this.orderData = {
        spotSupplyChainTransactionAmount: 40,
        futuresCapacityTradingAmount: 30,
        designInnovationServiceTransactionAmount: 15,
        trainingServiceTransactionAmount: 8,
        supplyChainFinancialTransactionAmount: 7,
      }
      this.industrialClusterData = {
        gaoYangRegisterNumber: 2343,
        gaoYangTransactionAmount: 7654440,
        linPingRegisterNumber: 1234,
        linPingTransactionAmount: 343456,
        puTianRegisterNumber: 345,
        puTianTransactionAmount: 3454333,
        yunHeRegisterNumber: 534,
        yunHeTransactionAmount: 5678876,
      }

      function creaateTrendData() {
        let trendDataArray = []
        let years = [2018, 2019, 2020, 2021, 2022]
        for (let i = 0; i < 5; i++) {
          trendDataArray.push({value: parseInt(Math.random() * 20 * (i+1)), year: years[i]})
        }
        return trendDataArray
      }
      
      this.trendData = {
        gaoYangSupplyChainTransactionPercent: creaateTrendData(),
        gaoYangCapacityTradingPercent:  creaateTrendData(),
        gaoYangTalentServicePercent:  creaateTrendData(),
        linPingSupplyChainTransactionPercent:  creaateTrendData(),
        linPingCapacityTradingPercent: creaateTrendData(),
        linPingTalentServicePercent:  creaateTrendData(),
        puTianSupplyChainTransactionPercent:  creaateTrendData(),
        puTianCapacityTradingPercent:  creaateTrendData(),
        puTianTalentServicePercent: creaateTrendData(),
        yunHeSupplyChainTransactionPercent: creaateTrendData(),
        yunHeCapacityTradingPercent:creaateTrendData(),
        yunHeTalentServicePercent: creaateTrendData(),
      }

      this.industryLinkageData = {
        // 产业联动效果图-地区资源链接数据
        gaoYangSupplyChainTransactionAmount: 4563322,
        gaoYangCooperativeProjectNumber: 13,
        gaoYangCapacityOrderNumber: 3432,
        yunHeSupplyChainTransactionAmount: 8754334,
        yunHeCooperativeProjectNumber: 24,
        yunHeCapacityOrderNumber: 4533,
        puTianSupplyChainTransactionAmount: 9843444,
        puTianCooperativeProjectNumber: 13,
        puTianCapacityOrderNumber: 3456,

        // 产业联动效果图-各地投资回报率
        yunHeReturnRate: 56,
        gaoYangReturnRate: 67,
        hangZhouReturnRate: 86,
      }
    },
    getData(flag) {
      if (flag === 'init') this.loading = true
      Promise.all([
        this.getDataApi(),
      ]).catch(e => {
        console.error(e)
      }).finally(() => {
        if (flag === 'init') this.loading = false
      })
    },
    // 获取数据
    getDataApi() {
      return ajaxDatav.get('/nationwide/getData').then(res => {
        console.log(res)
        if (res) {
          this.userData = {
            totalUserRegisterNumber: res.totalUserRegisterNumber,
            monthlyActiveNumber: res.monthlyActiveNumber,
            newRegistrationsThisMonthNumber: res.newRegistrationsThisMonthNumber,
            companyNumber: res.companyNumber,
          }

          this.projectData = {
            landedProjectNumber: res.landedProjectNumber,
            almostLandedProjectNumber: res.almostLandedProjectNumber,
            progressProjectNumber: res.progressProjectNumber,
          }

          this.orderData = {
            spotSupplyChainTransactionAmount: res.spotSupplyChainTransactionAmount,
            futuresCapacityTradingAmount: res.futuresCapacityTradingAmount,
            designInnovationServiceTransactionAmount: res.designInnovationServiceTransactionAmount,
            trainingServiceTransactionAmount: res.trainingServiceTransactionAmount,
            supplyChainFinancialTransactionAmount: res.supplyChainFinancialTransactionAmount,
          }
          this.industrialClusterData = {
            gaoYangRegisterNumber: res.gaoYangRegisterNumber,
            gaoYangTransactionAmount: res.gaoYangTransactionAmount,
            linPingRegisterNumber: res.linPingRegisterNumber,
            linPingTransactionAmount: res.linPingTransactionAmount,
            puTianRegisterNumber: res.puTianRegisterNumber,
            puTianTransactionAmount: res.puTianTransactionAmount,
            yunHeRegisterNumber: res.yunHeRegisterNumber,
            yunHeTransactionAmount: res.yunHeTransactionAmount,
          }

          this.trendData = {
            gaoYangSupplyChainTransactionPercent: res.gaoYangSupplyChainTransactionPercent || [],
            gaoYangCapacityTradingPercent: res.gaoYangCapacityTradingPercent || [],
            gaoYangTalentServicePercent: res.gaoYangTalentServicePercent || [],
            linPingSupplyChainTransactionPercent: res.linPingSupplyChainTransactionPercent || [],
            linPingCapacityTradingPercent: res.linPingCapacityTradingPercent || [],
            linPingTalentServicePercent: res.linPingTalentServicePercent || [],
            puTianSupplyChainTransactionPercent: res.puTianSupplyChainTransactionPercent || [],
            puTianCapacityTradingPercent: res.puTianCapacityTradingPercent || [],
            puTianTalentServicePercent: res.puTianTalentServicePercent || [],
            yunHeSupplyChainTransactionPercent: res.yunHeSupplyChainTransactionPercent || [],
            yunHeCapacityTradingPercent: res.yunHeCapacityTradingPercent || [],
            yunHeTalentServicePercent: res.yunHeTalentServicePercent || [],
          }

          this.industryLinkageData = {
            // 产业联动效果图-地区资源链接数据
            gaoYangSupplyChainTransactionAmount: res.gaoYangSupplyChainTransactionAmount,
            gaoYangCooperativeProjectNumber: res.gaoYangCooperativeProjectNumber,
            gaoYangCapacityOrderNumber: res.gaoYangCapacityOrderNumber,
            yunHeSupplyChainTransactionAmount: res.yunHeSupplyChainTransactionAmount,
            yunHeCooperativeProjectNumber: res.yunHeCooperativeProjectNumber,
            yunHeCapacityOrderNumber: res.yunHeCapacityOrderNumber,
            puTianSupplyChainTransactionAmount: res.puTianSupplyChainTransactionAmount,
            puTianCooperativeProjectNumber: res.puTianCooperativeProjectNumber,
            puTianCapacityOrderNumber: res.puTianCapacityOrderNumber,

            // 产业联动效果图-各地投资回报率
            yunHeReturnRate: res.yunHeReturnRate,
            gaoYangReturnRate: res.gaoYangReturnRate,
            hangZhouReturnRate: res.hangZhouReturnRate,
          }

        }
      })
    },
    
    
    
  }
}
</script>

<style lang="scss" scoped>
#data-view {
  width: 100%;
  height: 100%;
}
#dv-full-screen-container {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  transform-origin: left top;
  z-index: 999;
}
.container {
  background-color: #000066;
  background-image: url("../assets/imgs/bg.jpg");
  background-size: cover;
  background-position: center center;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  // filter: brightness(150%);
}
.main-content {
  // filter: none;
  display: flex;
  height: calc(100% - 112px);
  padding: 0 20px;

  // div {
  //   box-shadow: 0 0 1px 1px #666;
  // }
  .left-wrap {
    height: 100%;
    width: 25%;
    display: flex;
    flex-direction: column;
    .right-one {
      height: 50%;
      width: 100%;
    }
    .right-two {
      padding-top: 10px;
      height: 50%;
      width: 100%;
    }
  }

  .middle-wrap {
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    padding: 0 10px;

    .middle-top-wrap {
      height: 25%;
      width: 100%;
      display: flex;
    }
    .middle-top-one {
      height: 100%;
      width: 60%;
    }
    .middle-top-two {
      height: 100%;
      width: 40%;
    }
    .middle-bottom {
      height: 75%;
      width: 100%;
    }
  }
  
  .right-wrap {
    height: 100%;
    width: 25%;
    display: flex;
    flex-direction: column;

    .right-one {
      height: 25%;
      width: 100%;
    }
    .right-two {
      padding-top: 10px;
      height: 75%;
      width: 100%;
    }
  }

}
</style>