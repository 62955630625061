<template>
  <!-- <dv-border-box-6> -->
  <v-chart class="chart" :option="option" autoresize @click="mapClick" />
  <!-- </dv-border-box-6> -->
</template>

<script>
// import { use } from 'echarts/core';
// import { CanvasRenderer } from 'echarts/renderers';
// import { PieChart } from 'echarts/charts';
// import {
//   TitleComponent,
//   TooltipComponent,
//   LegendComponent,
// } from 'echarts/components';
// import VChart, { THEME_KEY } from 'vue-echarts';
// import { ref, defineComponent } from 'vue';
import { use, registerMap } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import {
  MapChart,
  ScatterChart,
  EffectScatterChart,
  LinesChart,
} from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";
import { ref, defineComponent } from "vue";
import chinaMap from "../utils/china.json";

use([
  CanvasRenderer,
  MapChart,
  ScatterChart,
  EffectScatterChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  LinesChart,
]);

registerMap("china", chinaMap);

var chinaGeoCoordMap = {
  高阳: [115.78,38.70],
  云和: [119.57,28.12],
  莆田: [119.01,25.45],
  杭州: [120.30,30.38],
  // 黑龙江: [127.9688, 45.368],
  // 内蒙古: [110.3467, 41.4899],
  // 吉林: [125.8154, 44.2584],
  // 北京市: [116.4551, 40.2539],
  // 辽宁: [123.1238, 42.1216],
  // 河北: [114.4995, 38.1006],
  // 天津: [117.4219, 39.4189],
  // 山西: [112.3352, 37.9413],
  // 陕西: [109.1162, 34.2004],
  // 甘肃: [103.5901, 36.3043],
  // 宁夏: [106.3586, 38.1775],
  // 青海: [101.4038, 36.8207],
  // 新疆: [87.9236, 43.5883],

  // 西藏: [91.11, 29.97],
  // 四川: [103.9526, 30.7617],
  // 重庆: [108.384366, 30.439702],
  // 山东: [117.1582, 36.8701],
  // 河南: [113.4668, 34.6234],
  // 江苏: [118.8062, 31.9208],
  // 安徽: [117.29, 32.0581],
  // 湖北: [114.3896, 30.6628],
  // 浙江: [119.5313, 29.8773],
  // 福建: [119.4543, 25.9222],
  // 江西: [116.0046, 28.6633],
  // 湖南: [113.0823, 28.2568],
  // 贵州: [106.6992, 26.7682],
  // 云南: [102.9199, 25.4663],
  // 广东: [113.12244, 23.009505],
  // 广西: [108.479, 23.1152],
  // 海南: [110.3893, 19.8516],
  // 上海: [121.4648, 31.2891],
};
var chinaDatas = [
  [{name: "高阳", value: 0}],
  [{name: "云和", value: 0}],
  [{name: "莆田", value: 0}],
  [{name: "杭州", value: 0}],
  // [
  //   {
  //     name: "黑龙江",
  //     value: 0,
  //   },
  // ],
  // [
  //   {
  //     name: "内蒙古",
  //     value: 0,
  //   },
  // ],
  // [
  //   {
  //     name: "吉林",
  //     value: 0,
  //   },
  // ],
  // [
  //   {
  //     name: "辽宁",
  //     value: 0,
  //   },
  // ],
  // [
  //   {
  //     name: "河北",
  //     value: 0,
  //   },
  // ],
  // [
  //   {
  //     name: "天津",
  //     value: 0,
  //   },
  // ],
  // [
  //   {
  //     name: "山西",
  //     value: 0,
  //   },
  // ],
  // [
  //   {
  //     name: "陕西",
  //     value: 0,
  //   },
  // ],
  // [
  //   {
  //     name: "甘肃",
  //     value: 0,
  //   },
  // ],
  // [
  //   {
  //     name: "宁夏",
  //     value: 0,
  //   },
  // ],
  // [
  //   {
  //     name: "青海",
  //     value: 0,
  //   },
  // ],
  // [
  //   {
  //     name: "新疆",
  //     value: 0,
  //   },
  // ],
  // [
  //   {
  //     name: "西藏",
  //     value: 0,
  //   },
  // ],
  // [
  //   {
  //     name: "四川",
  //     value: 0,
  //   },
  // ],
  // [
  //   {
  //     name: "重庆",
  //     value: 0,
  //   },
  // ],
  // [
  //   {
  //     name: "山东",
  //     value: 0,
  //   },
  // ],
  // [
  //   {
  //     name: "河南",
  //     value: 0,
  //   },
  // ],
  // [
  //   {
  //     name: "江苏",
  //     value: 0,
  //   },
  // ],
  // [
  //   {
  //     name: "安徽",
  //     value: 0,
  //   },
  // ],
  // [
  //   {
  //     name: "湖北",
  //     value: 0,
  //   },
  // ],
  // [
  //   {
  //     name: "浙江",
  //     value: 0,
  //   },
  // ],
  // [
  //   {
  //     name: "福建",
  //     value: 0,
  //   },
  // ],
  // [
  //   {
  //     name: "江西",
  //     value: 0,
  //   },
  // ],
  // [
  //   {
  //     name: "湖南",
  //     value: 0,
  //   },
  // ],
  // [
  //   {
  //     name: "贵州",
  //     value: 0,
  //   },
  // ],
  // [
  //   {
  //     name: "广西",
  //     value: 0,
  //   },
  // ],
  // [
  //   {
  //     name: "海南",
  //     value: 0,
  //   },
  // ],
  // [
  //   {
  //     name: "上海",
  //     value: 1,
  //   },
  // ],
];
var convertData = function (data) {
  var res = [];
  for (var i = 0; i < data.length; i++) {
    var dataItem = data[i];
    var fromCoord = chinaGeoCoordMap[dataItem[0].name];
    var toCoord = [120.30,30.38];
    if (fromCoord && toCoord) {
      res.push([
        {
          coord: fromCoord,
          value: dataItem[0].value,
        },
        {
          coord: toCoord,
        },
      ]);
    }
  }
  return res;
};
var series = [];
[["杭州", chinaDatas]].forEach(function (item) {
  console.log(item);
  series.push(
    {
      type: "lines",
      zlevel: 2,
      effect: {
        show: true,
        period: 4, //箭头指向速度，值越小速度越快
        trailLength: 0.02, //特效尾迹长度[0,1]值越大，尾迹越长重
        symbolSize: 4,
        // symbol: "triangle",
        // symbolSize: [10, 10], //图标大小
      },
      lineStyle: {
        color: '#F74945', // 红色
        width: 1, //尾迹线条宽度
        opacity: 0.8, //尾迹线条透明度
        curveness: -0.24, //尾迹线条曲直度
        
      },
      data: convertData(item[1]),
    },
    {
      type: "lines",
      zlevel: 2,
      effect: {
        show: true,
        period: 4, //箭头指向速度，值越小速度越快
        trailLength: 0.02, //特效尾迹长度[0,1]值越大，尾迹越长重
        symbolSize: 4,
        // symbol: "triangle",
        // symbolSize: [10, 10], //图标大小
      },
      lineStyle: {
        color: '#3D3CF1', // 紫色
        width: 1, //尾迹线条宽度
        opacity: 0.8, //尾迹线条透明度
        curveness: -0.4, //尾迹线条曲直度
      },
      data: convertData(item[1]),
    },
    {
      type: "lines",
      zlevel: 2,
      effect: {
        show: true,
        period: 4, //箭头指向速度，值越小速度越快
        trailLength: 0.02, //特效尾迹长度[0,1]值越大，尾迹越长重
        symbolSize: 4,
        // symbol: "triangle",
        // symbolSize: [10, 10], //图标大小
      },
      lineStyle: {
        color: '#FFBB2C', // 黄色
        width: 1, //尾迹线条宽度
        opacity: 0.8, //尾迹线条透明度
        curveness: 0.64, //尾迹线条曲直度
        
      },
      data: convertData(item[1]),
    },
    {
      type: "lines",
      zlevel: 2,
      effect: {
        show: true,
        period: 4, //箭头指向速度，值越小速度越快
        trailLength: 0.02, //特效尾迹长度[0,1]值越大，尾迹越长重
        symbolSize: 4,
        // symbol: "triangle",
        // symbolSize: [10, 10], //图标大小
      },
      lineStyle: {
        color: '#4EF0FF', // 绿色
        width: 1, //尾迹线条宽度
        opacity: 0.8, //尾迹线条透明度
        curveness: 0.24, //尾迹线条曲直度
        
      },
      data: convertData(item[1]),
    },
    {
      type: "effectScatter",
      coordinateSystem: "geo",
      zlevel: 2,
      rippleEffect: {
        //涟漪特效
        period: 4, //动画时间，值越小速度越快
        brushType: "stroke", //波纹绘制方式 stroke, fill
        scale: 4, //波纹圆环最大限制，值越大波纹越大
      },
      label: {
        normal: {
          show: false,
          position: "right", //显示位置
          offset: [5, 0], //偏移设置
          formatter: function (params) {
            //圆环显示文字
            return params.data.name;
          },
          fontSize: 13,
        },
        emphasis: {
          show: true,
        },
      },
      symbol: "circle",
      symbolSize: function (val) {
        return 10 + val[2] * 5; //圆环大小
      },
      itemStyle: {
        color: "#29F8F1",
      },
      data: item[1].map(function (dataItem) {
        console.log(chinaGeoCoordMap[dataItem[0].name].concat([dataItem[0].value]))
        return {
          name: dataItem[0].name,
          value: chinaGeoCoordMap[dataItem[0].name].concat([dataItem[0].value]),
        };
      }),
      tooltip: {
        valueFormatter: () => { return ''}
      }
    },
    // 被攻击点
    {
      type: "effectScatter",
      coordinateSystem: "geo",
      zlevel: 2,
      rippleEffect: {
        period: 4,
        brushType: "stroke",
        scale: 4,
      },
      itemStyle: {
        color: '#29F8F1'
      },
      label: {
        normal: {
          show: false,
          position: "right",
          //offset:[5, 0],
          color: "#0f0",
          formatter: "{b}",
          textStyle: {
            color: "#0f0",
          },
        },
        emphasis: {
          show: true,
          color: "#f60",
        },
      },
      symbol: "circle",
      symbolSize: 15,
      data: [
        {
          name: item[0],
          value: chinaGeoCoordMap[item[0]].concat([10]),
        },
      ],
      tooltip: {
        valueFormatter: () => { return ''}
      }
    }
  );
});

export default defineComponent({
  name: "ChinaMap",
  components: {
    VChart,
  },
  provide: {
    [THEME_KEY]: "dark",
  },
  methods: {
    mapClick(e) {
      console.log(e);
      if (e.componentType === "geo" && e.name === "浙江") {
        window.open('https://datav.aliyuncs.com/share/c53490954456e2634851ef46253ee7ba')
      }
    },
  },
  setup() {
    const option = ref({
      // textStyle: {
      //   fontFamily: 'Inter, "Helvetica Neue", Arial, sans-serif'
      // },
      backgroundColor: "transparent",
      // title: {
      //   text: "全国产业一张网",
      //   subtext: "产业“虚拟集团”生态平台 EPIVG",
      //   left: "center",
      //   textStyle: {
      //     color: "#fff",
      //     fontSize: "28px",
      //     fontWeight: "bold"
      //   }
      // },
      label: {
        show: true,
      },
      tooltip: {
        trigger: "item",
      },
      geo: {
        zoom: 1.14,
        map: "china",
        roam: true,
        label: {
          show: true,
          color: "#fff",
        },
        emphasis: {
          label: {
            show: true,
            color: "#fff",
          },
          itemStyle: {
            areaColor : {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: "#FCA901", // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: "#FE6902", // 100% 处的颜色
                },
              ],
              global: false, // 缺省为 false
            },
          },
        },
        itemStyle: {
          areaColor: '#0178FC',
          // : {
          //   type: "linear",
          //   x: 0,
          //   y: 0,
          //   x2: 0,
          //   y2: 1,
          //   colorStops: [
          //     {
          //       offset: 0,
          //       color: "#6924fe", // 0% 处的颜色
          //     },
          //     {
          //       offset: 1,
          //       color: "#188df0", // 100% 处的颜色
          //     },
          //   ],
          //   global: false, // 缺省为 false
          // },
          borderWidth: 1.5,
          borderColor: "#fff",
          // shadowColor: "#fff",
          // shadowOffsetX: -1,
          // shadowOffsetY: 1,
          // shadowBlur: 1,

          // borderColor: "rgba(147, 235, 248, 1)",
          // shadowColor: "rgba(128, 217, 248, 1)",
          // shadowOffsetX: -2,
          // shadowOffsetY: 2,
          // shadowBlur: 10,
        },

        regions: [{
          name: '新疆',
          itemStyle: {
            areaColor: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 1,
              y2: 0.8,
              colorStops: [
                {
                  offset: 0,
                  color: "#6FC7FF", // 0% 处的颜色
                },
                {
                  offset: 0.8,
                  color: "#0178FC",
                },
                {
                  offset: 1,
                  color: "#0178FC", // 100% 处的颜色
                },
              ],
              global: false, // 缺省为 false
            },
          },
        }, {
          name: '西藏',
          itemStyle: {
            areaColor: {
              type: "linear",
              x: 0,
              y: 1,
              x2: 0.5,
              y2: 0,
              colorStops: [
                {
                  offset: 0,
                  color: "#6FC7FF", // 0% 处的颜色
                },
                {
                  offset: 0.8,
                  color: "#0178FC",
                },
                {
                  offset: 1,
                  color: "#0178FC", // 100% 处的颜色
                },
              ],
              global: false, // 缺省为 false
            },
          },
        }]
      },
      // legend: {
      //   orient: "vertical",
      //   y: "bottom",
      //   x: "right",
      //   data: ["pm2.5"],
      //   textStyle: {
      //     color: "#fff"
      //   }
      // },
      // visualMap: [{
      //   type: 'piecewise',
      //   min: 10,
      //   max: 600,
      //   text: ['High', 'Low'],
      //   realtime: false,
      //   calculable: true,
      //   inRange: {
      //     color: ['lightskyblue', 'yellow', 'orangered']
      //   }
      // }],
      series: series,
    });

    return { option };
  },
});
</script>

<style scoped>
.chart {
  height: 100%;
}
</style>
