<template>
  <div id="top-header">
    <!-- <dv-decoration-10 class="header-left-decoration"/> -->
     <!-- <dv-decoration-8 class="header-left-decoration" :color="['rgba(124, 231, 253, 1)', 'rgba(124, 231, 253, 1)']" /> -->
    <!-- <dv-decoration-5 :dur="2" class="header-center-decoration" :color="['rgba(124, 231, 253, 1)', 'rgba(124, 231, 253, 1)']" /> -->
    <!-- <dv-decoration-8 class="header-right-decoration" :reverse="true" :color="['rgba(124, 231, 253, 1)', 'rgba(124, 231, 253, 1)']" -->
    <!-- <dv-decoration-10 class="header-right-decoration"/> -->
    <!-- <dv-decoration-3 class="header-center-decoration" /> -->
    <!-- <dv-decoration-6 class="header-center-decoration" /> -->
    <div class="center-title">
      EPIVG产业虚拟集团生态平台全国布局数据中心
    </div>
  </div>
</template>

<script>
export default {
  name: 'TopHeader'
}
</script>

<style lang="scss">
#top-header {
  position: relative;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  background: url(../assets/imgs/bg_title.png) no-repeat;
  background-size: 100% 80%;
  .header-center-decoration {
    width: 600px;
    height: 24px;
    margin-top: 50px;
  }
  .center-title {
    position: absolute;
    font-size: 30px;
    letter-spacing: 2px;
    color: #fff;
    left: 50%;
    top: 24px;
    font-weight: bold;
    transform: translateX(-50%);
  }
}
</style>