<template>
  <div class="mod-tit-wrap">
    <i class="arrow" :class="[icon ? icon : '']"></i>
    <div :class="['bg', type]">
      <slot></slot>
    </div>
  </div>
</template>

<script>
// import bgTitleL from '../assets/bg_title_l.png'
// import bgTitleM from '../assets/bg_title_m.png'
// import bgTitleS from '../assets/bg_title_s.png'
export default {
  props: {
    type: {
      type: String,
      default: 'm'
    },
    icon: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.mod-tit-wrap {
  display: flex;
  align-items: center;
  .arrow {
    margin-bottom: 14px;
    width: 16px;
    height: 13px;
    background: url(../assets/imgs/ic_title_arrow.png) no-repeat;
    background-size: 100% 100%;
    &.project {
      margin-right: 2px;
      width: 34px;
      height: 34px;
      background-image: url(../assets/imgs/icon_project.png);
    }
    &.user {
      margin-right: 2px;
      width: 34px;
      height: 34px;
      background-image: url(../assets/imgs/icon_user.png);
    }
  }
  .bg {
    width: 100%;
    height: 34px;
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: left bottom;
    padding-left: 16px;
    font-size: 18px;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 20px;
    &.s {
      background-image: url(../assets/imgs/bg_title_s.png);
    }
    &.m {
      background-image: url(../assets/imgs/bg_title_m.png);
    }
    &.l {
      background-image: url(../assets/imgs/bg_title_l.png);
    }
    &.n {
      background: none;
      padding-left: 0;
    }
  }
}
</style>