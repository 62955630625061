<template>
  <!-- <dv-border-box-12> -->
    <div class="item-wrapper">
      <module-title type="m">订单数据</module-title>
      <!-- <div class="item-title">订单数据</div> -->
      <v-chart ref="pieChart" class="chart" :option="option" autoresize />
    </div>
  <!-- </dv-border-box-12> -->
</template>

<script>
import { use } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { PieChart } from 'echarts/charts';

import VChart, { THEME_KEY } from 'vue-echarts';
import { defineComponent, computed } from 'vue';
import ModuleTitle from "../components/ModuleTitle";

use([
  CanvasRenderer,
  PieChart,
]);


export default defineComponent({
  name: 'OrderMap',
  components: {
    VChart, ModuleTitle
  },
  provide: {
    [THEME_KEY]: 'dark',
  },
  props: {
    originData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      timer: null
    }
  },
  mounted() {
    let i = -1
    this.timer = setInterval(() => {
      this.$refs.pieChart.dispatchAction({
        type: 'downplay',
        seriesIndex: 0,
        dataIndex: i,
      })
      if (i >= this.option.series[0].data.length - 1) { i = 0 }
      else { i ++ }
      this.$refs.pieChart.dispatchAction({
        type: 'highlight',
        seriesIndex: 0,
        dataIndex: i,
      })
    }, 2000)
  },
  destroyed() {
    clearInterval(this.timer)
  },
  setup(props) {
    const option = computed(() => {
      return ({
      // title: {
      //   text: 'Referer of a Website',
      //   subtext: 'Fake Data',
      //   left: 'center'
      // },
      backgroundColor: "rgba(3, 21, 84, 0.8)",
      color: ['#00a2ff', '#ffd600', '#9254DE', '#F58122', '#5be241'],
      tooltip: {
        trigger: 'item'
      },
      // legend: {
      //   orient: 'vertical',
      //   left: 'left'
      // },
      series: [
        {
          name: '订单数据-总交易额（元）',
          type: 'pie',
          radius: ['70%', '85%'],
          selectedMode: 'single',
          // selectedOffset: 0,
          data: [
            { value: props.originData.spotSupplyChainTransactionAmount, name: '现货供应链交易额'},
            { value: props.originData.futuresCapacityTradingAmount, name: '期货产能交易额' },
            { value: props.originData.designInnovationServiceTransactionAmount, name: '设计创新服务交易额'},
            { value: props.originData.trainingServiceTransactionAmount, name: '培训服务交易额' },
            { value: props.originData.supplyChainFinancialTransactionAmount, name: '供应链金融交易额' }
          ],
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: 'center',
            formatter: '{d|{d}%}\n\n{b}',
            rich: {
              d: {
                fontSize: 32,
              }
            }
          },
          emphasis: {
            label: {
              show: true,
              color: '#fff',
              fontSize: 14,
              fontWeight: 'bold'
            },
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }
      ]})
    })
    return { option }
  },
})
</script>