<template>
  <!-- <dv-border-box-12> -->
    <div class="item-wrapper">
      <module-title type="m">产业集群</module-title>
      <!-- <div class="item-title">产业集群类（已落地项目）</div> -->
      <v-chart class="chart" :option="option" autoresize />
    </div>
  <!-- </dv-border-box-12> -->
</template>

<script>
import { use } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { BarChart } from 'echarts/charts';
import VChart, { THEME_KEY } from 'vue-echarts';
import { computed, defineComponent } from 'vue';
import ModuleTitle from "../components/ModuleTitle";


use([
  CanvasRenderer,
  BarChart
]);


export default defineComponent({
  name: 'OrderMap',
  components: {
    VChart, ModuleTitle
  },
  props: {
    originData: {
      type: Object,
      default: () => {}
    }
  },
  provide: {
    [THEME_KEY]: 'dark',
  },
  setup(props) {
    const colors =[
      {
        type: 'linear',
        x: 0,
        y: 0,
        x2: 0,
        y2: 1,
        colorStops: [{
            offset: 0, color: '#47ffc9' // 0% 处的颜色
        }, {
            offset: 1, color: '#5a77ff' // 100% 处的颜色
        }],
        global: false // 缺省为 false
      }, 
      {
        type: 'linear',
        x: 0,
        y: 0,
        x2: 0,
        y2: 1,
        colorStops: [{
            offset: 0, color: '#ffa430' // 0% 处的颜色
        }, {
            offset: 1, color: '#ef326c' // 100% 处的颜色
        }],
        global: false // 缺省为 false
      }]
    const option = computed(() => ({
      backgroundColor: 'rgba(3, 21, 84, 0.8)',
      color: colors,
      textStyle: {
        color: '#fff'
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross'
        }
      },
      grid: {
        left: '15%',
        right: '15%'
      },
      // toolbox: {
      //   feature: {
      //     dataView: { show: true, readOnly: false },
      //     restore: { show: true },
      //     saveAsImage: { show: true }
      //   }
      // },
      // legend: {
      //   data: ['项目注册用户', '订单交易总额']
      // },
      xAxis: [
        {
          type: 'category',
          axisTick: {
            alignWithLabel: true
          },
          // prettier-ignore
          data: ['高阳', '临平', '莆田', '云和']
        }
      ],
      yAxis: [
        {
          type: 'value',
          name: '项目注册用户（人）',
          position: 'left',
          alignTicks: true,
          axisLine: {
            show: true,
            lineStyle: {
              color: colors[0]
            }
          },
          splitLine: {
            show: true,
            lineStyle:{
              color: '#999',
              type: 'dotted'
            }
          },
          // axisLabel: {
          //   formatter: '{value} 人'
          // }
        },
        {
          type: 'value',
          name: '订单交易总额（元）',
          position: 'right',
          alignTicks: true,
          axisLine: {
            show: true,
            lineStyle: {
              color: colors[1]
            }
          },
          splitLine: {
            show: false,
          },
          // axisLabel: {
          //   formatter: '{value} 元'
          // }
          // max: function (value) {
          //   return value.max - 100
          // },
          // min: 0
        },
      ],
      series: [
        {
          name: '项目注册用户',
          type: 'bar',
          data: [
            props.originData.gaoYangRegisterNumber,
            props.originData.linPingRegisterNumber,
            props.originData.puTianRegisterNumber,
            props.originData.yunHeRegisterNumber,
          ],
          barWidth: 20, 
          barGap: '40%',
          itemStyle: {
            borderRadius: 10, // 统一设置四个角的圆角大小
          }
        },
        {
          name: '订单交易总额',
          type: 'bar',
          yAxisIndex: 1,
          data: [
            props.originData.gaoYangTransactionAmount,
            props.originData.linPingTransactionAmount,
            props.originData.puTianTransactionAmount,
            props.originData.yunHeTransactionAmount,
          ],
          barWidth: 20, 
          itemStyle: {
            borderRadius: 10, // 统一设置四个角的圆角大小
          }
        },
      ]
    }))
    return { option }
  },
})
</script>