<template>
  <!-- <dv-border-box-12> -->
    <div class="item-wrapper">
      <module-title type="m">项目运营提升百分比</module-title>
      <!-- <div class="item-title">各地项目运营落地后的变化（提升百分比）</div> -->
      <v-chart class="chart" :option="option" autoresize />
    </div>
  <!-- </dv-border-box-12> -->
</template>

<script>
import { use } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { BarChart, LineChart } from 'echarts/charts';
import VChart, { THEME_KEY } from 'vue-echarts';
import { ref, defineComponent } from 'vue';
import ModuleTitle from "../components/ModuleTitle";

use([
  CanvasRenderer,
  BarChart,
  LineChart
]);

function createTrendData(arr) {
  return arr.map(item => item.value)
}

function createYearData(arr) {
  return arr.map(item => item.year)
}

const colors = ['#00a2ff', '#ffd600', '#A54EFF', '#5be241']

const areaColors = [
  {
    type: 'linear',
    x: 0,
    y: 0,
    x2: 0,
    y2: 1,
    colorStops: [{
        offset: 0, color: 'rgba(0, 162, 255, 0.5)' // 0% 处的颜色
    }, {
        offset: 1, color: 'rgba(0, 162, 255, 0.1)' // 100% 处的颜色
    }],
    global: false // 缺省为 false
  },
  {
    type: 'linear',
    x: 0,
    y: 0,
    x2: 0,
    y2: 1,
    colorStops: [{
        offset: 0, color: 'rgba(255, 214, 0, 0.5)' // 0% 处的颜色
    }, {
        offset: 1, color: 'rgba(255, 214, 0, 0.1)' // 100% 处的颜色
    }],
    global: false // 缺省为 false
  },
  {
    type: 'linear',
    x: 0,
    y: 0,
    x2: 0,
    y2: 1,
    colorStops: [{
        offset: 0, color: 'rgba(165, 78, 255, 0.5)' // 0% 处的颜色
    }, {
        offset: 1, color: 'rgba(165, 78, 255, 0.1)' // 100% 处的颜色
    }],
    global: false // 缺省为 false
  },
  {
    type: 'linear',
    x: 0,
    y: 0,
    x2: 0,
    y2: 1,
    colorStops: [{
        offset: 0, color: 'rgba(91, 226, 65, 0.5)' // 0% 处的颜色
    }, {
        offset: 1, color: 'rgba(91, 226, 65, 0.1)' // 100% 处的颜色
    }],
    global: false // 缺省为 false
  }

  
]
const legendData = [
  '高阳-供应链', '高阳-产能', '高阳-人才服务', 
  '临平-供应链', '临平-产能', '临平-人才服务', 
  '莆田-供应链', '莆田-产能', '莆田-人才服务', 
  '云和-供应链', '云和-产能', '云和-人才服务', 
]

export default defineComponent({
  name: 'TrendData',
  components: {
    VChart, ModuleTitle
  },
  props: {
    originData: {
      type: Object,
      default: () => {}
    }
  },
  provide: {
    [THEME_KEY]: 'dark',
  },
  computed: {
    seriesData: function() {
      return [
        {
          name: '高阳-供应链',
          type: 'line',
          data: createTrendData(this.originData.gaoYangSupplyChainTransactionPercent),
          symbol: 'circle', // circle 'rect', 'roundRect', 'triangle', 'diamond', 'pin', 'arrow', 'none'
          symbolSize: 12,
          smooth: true,
          lineStyle: {
            color: colors[0],
            width: 1,
          },
          itemStyle: {
            color: colors[0]
          },
          areaStyle: {
            color: areaColors[0],
          }
        },
        {
          name: '高阳-产能',
          type: 'line',
          data: createTrendData(this.originData.gaoYangCapacityTradingPercent),
          symbol: 'rect',
          symbolSize: 12,
          smooth: true,
          lineStyle: {
            color: colors[0],
            width: 1,
          },
          itemStyle: {
            color: colors[0]
          },
          areaStyle: {
            color: areaColors[0],
            shadowColor: '#000',
          }
        },
        {
          name: '高阳-人才服务',
          type: 'line',
          data: createTrendData(this.originData.gaoYangTalentServicePercent),
          symbol: 'triangle',
          symbolSize: 12,
          smooth: true,
          lineStyle: {
            color: colors[0],
            width: 1,
          },
          itemStyle: {
            color: colors[0]
          },
          areaStyle: {
            color: areaColors[0],
          }
        },

        {
          name: '临平-供应链',
          type: 'line',
          data: createTrendData(this.originData.linPingSupplyChainTransactionPercent),
          symbol: 'circle',
          symbolSize: 12,
          smooth: true,
          lineStyle: {
            color: colors[1],
            width: 1,
          },
          itemStyle: {
            color: colors[1]
          },
          areaStyle: {
            color: areaColors[1],
          }
        },
        {
          name: '临平-产能',
          type: 'line',
          data: createTrendData(this.originData.linPingCapacityTradingPercent),
          symbol: 'rect',
          symbolSize: 12,
          smooth: true,
          lineStyle: {
            color: colors[1],
            width: 1,
          },
          itemStyle: {
            color: colors[1]
          },
          areaStyle: {
            color: areaColors[1],
          }
        },
        {
          name: '临平-人才服务',
          type: 'line',
          data: createTrendData(this.originData.linPingTalentServicePercent),
          symbol: 'triangle',
          symbolSize: 12,
          smooth: true,
          lineStyle: {
            color: colors[1],
            width: 1,
          },
          itemStyle: {
            color: colors[1]
          },
          areaStyle: {
            color: areaColors[1],
          }
        },

        {
          name: '莆田-供应链',
          type: 'line',
          data: createTrendData(this.originData.puTianSupplyChainTransactionPercent),
          symbol: 'circle',
          symbolSize: 12,
          smooth: true,
          lineStyle: {
            color: colors[2],
            width: 1,
          },
          itemStyle: {
            color: colors[2]
          },
          areaStyle: {
            color: areaColors[2],
          }
        },
        {
          name: '莆田-产能',
          type: 'line',
          data: createTrendData(this.originData.puTianCapacityTradingPercent),
          symbol: 'rect',
          symbolSize: 12,
          smooth: true,
          lineStyle: {
            color: colors[2],
            width: 1,
          },
          itemStyle: {
            color: colors[2]
          },
          areaStyle: {
            color: areaColors[2],
          }
        },
        {
          name: '莆田-人才服务',
          type: 'line',
          data: createTrendData(this.originData.puTianTalentServicePercent),
          symbol: 'triangle',
          symbolSize: 12,
          smooth: true,
          lineStyle: {
            color: colors[2],
            width: 1,
          },
          itemStyle: {
            color: colors[2]
          },
          areaStyle: {
            color: areaColors[2],
          }
        },

        {
          name: '云和-供应链',
          type: 'line',
          data: createTrendData(this.originData.yunHeSupplyChainTransactionPercent),
          symbol: 'circle',
          symbolSize: 12,
          smooth: true,
          lineStyle: {
            color: colors[3],
            width: 1,
          },
          itemStyle: {
            color: colors[3]
          },
          areaStyle: {
            color: areaColors[3],
          }
        },
        {
          name: '云和-产能',
          type: 'line',
          data: createTrendData(this.originData.yunHeCapacityTradingPercent),
          symbol: 'rect',
          symbolSize: 12,
          smooth: true,
          lineStyle: {
            color: colors[3],
            width: 1,
          },
          itemStyle: {
            color: colors[3]
          },
          areaStyle: {
            color: areaColors[3],
          }
        },
        {
          name: '云和-人才服务',
          type: 'line',
          data: createTrendData(this.originData.yunHeTalentServicePercent),
          symbol: 'triangle',
          symbolSize: 12,
          smooth: true,
          lineStyle: {
            color: colors[3],
            width: 1,
          },
          itemStyle: {
            color: colors[3]
          },
          areaStyle: {
            color: areaColors[3],
          }
        },
      ]
    },
    xAxisData: function() {
      return [
        createYearData(this.originData.gaoYangSupplyChainTransactionPercent),
        createYearData(this.originData.linPingSupplyChainTransactionPercent),
        createYearData(this.originData.puTianSupplyChainTransactionPercent),
        createYearData(this.originData.yunHeSupplyChainTransactionPercent),
      ]
    },
  },
  mounted() {
    let i = 0
    this.option.legend.data = legendData.slice(i * 3, i * 3 + 3)
    this.option.series = this.seriesData.slice(i * 3, i * 3 + 3)
    this.option.xAxis.data = this.xAxisData[i]
    
    setInterval(() => {
      if (i >= 3) i = 0
      else i ++
      this.option.legend.data = legendData.slice(i * 3, i * 3 + 3)
      this.option.series = this.seriesData.slice(i * 3, i * 3 + 3)
      this.option.xAxis.data = this.xAxisData[i]
    }, 2000)
    
  },
  setup() {
   
    const option = ref({
      backgroundColor: 'rgba(3, 21, 84, 0.8)',
      // title: {
      //   text: 'Stacked Line'
      // },
      textStyle: {
        color: '#fff'
      },
      tooltip: {
        trigger: 'axis',
        valueFormatter: (value) => value + '%'
      },
      legend: {
        // show: false,
        // orient: 'vertical',
        // bottom: 'bottom',
        top: 20,
        data: [],
        textStyle: {
          color: '#fff'
        }
      },
      grid: {
        // top: '14%',
        // containLabel: true
      },
      // grid: {
      //   left: '3%',
      //   right: '4%',
      //   bottom: '3%',
      //   containLabel: true
      // },
      // toolbox: {
      //   feature: {
      //     saveAsImage: {}
      //   }
      // },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: [],
        name: '年'
      },
      yAxis: {
        type: 'value',
        name: '（%）        ',
        splitLine: {
          lineStyle:{
            color: '#999',
            type: 'dotted'
          }
        },
      },
      
      series: []
    })
    return { option }
  },
})
</script>