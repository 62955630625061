<template>
  <!-- <dv-border-box-6> -->
    <div class="user-data">
      <module-title type="n" icon="user">总用户数据</module-title>
      <!-- <h2 class="title">总用户数据</h2> -->
      <div class="data-wrap">
        <div class="item">
          <div class="lab">总注册用户数量</div>
          <dv-digital-flop class="num" :config="conf.conf1"/>
        </div>
        <div class="item">
          <div class="lab">月活数量</div>
          <dv-digital-flop class="num" :config="conf.conf2" />
        </div>
      </div>
      <div class="data-wrap">
        <div class="item">
          <div class="lab">本月新注册数量</div>
          <dv-digital-flop class="num" :config="conf.conf3" />
        </div>
        <div class="item">
          <div class="lab">公司数量</div>
          <dv-digital-flop class="num" :config="conf.conf4"  />
        </div>
      </div>
    </div>
  <!-- </dv-border-box-6> -->
</template>

<script>
import ModuleTitle from "../components/ModuleTitle";
function formatter (number) {
  const numbers = number.toString().split('').reverse()
  const segs = []

  while (numbers.length) segs.push(numbers.splice(0, 3).join(''))

  return segs.join(',').split('').reverse().join('')
}
export default {
  components: {
    ModuleTitle
  },
  props: {
    originData: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    conf: function() {
      return {
        conf1: {
          number: [this.originData.totalUserRegisterNumber],
          content: '{nt}人',
          textAlign: 'left',
          style: {
            fontSize: '34',
            fill: '#fff600',
          },
          formatter
        },
        conf2: {
          number: [this.originData.monthlyActiveNumber],
          content: '{nt}人',
          textAlign: 'left',
          style: {
            fontSize: '34',
            fill: '#fff600',
          },
          formatter
        },
        conf3: {
          number: [this.originData.newRegistrationsThisMonthNumber],
          content: '{nt}人',
          textAlign: 'left',
          style: {
            fontSize: '34',
            fill: '#fff600',
          },
          formatter
        },
        conf4: {
          number: [this.originData.companyNumber],
          content: '{nt}家',
          textAlign: 'left',
          style: {
            fontSize: '34',
            fill: '#fff600',
          },
          formatter
        },
      }
    }
  },
  data() {
    return {}
  },
}
</script>

<style lang="scss" scoped>
.user-data {
  padding: 28px;
  .title {
    font-weight: normal;
    margin-bottom: 30px;
  }
}
.data-wrap {
  display: flex;
  .item {
    width: 50%;
    font-size: 20px;
    .lab {
      font-size: 18px;
      margin: 12px 0 6px;
    }
    .num {
      height: 54px;
    }
    &:first-child {
      width: 55%;
    }
    &:last-child {
      width: 45%;
    }
  }
}
</style>